<template>
  <v-app>
    <div class="menu-icon" :style="{ width: menu_icon_width + 'px' }">
      <div v-for="menu in menu_list" :key="menu.name" link>
        <v-tooltip right v-if="menu.show || menu.show == 2">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="menu.url"
              class="menu-icon-list py-2"
              @click="(menu_selected = menu), goUrl(menu.url)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon right color="white">{{ menu.icon }}</v-icon>
            </div>
            <div
              v-else
              class="menu-icon-list py-2"
              @click="(menu_selected = menu), (drawer = false)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon right color="white">{{ menu.icon }}</v-icon>
            </div>
          </template>
          <span>{{ menu.text }}</span>
        </v-tooltip>
      </div>

      <div style="position: fixed; bottom: 0px; width: 50px">
        <!-- <div link>
          <div v-for="menu2 in menu_list2" :key="menu2.name" link>
            <v-tooltip right v-if="menu2.show || menu2.show == 2">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-if="menu2.url"
                  class="menu-icon-list py-2"
                  @click="(menu_selected = menu2), goUrl(menu2.url)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon right color="white">{{ menu2.icon }}</v-icon>
                </div>

                <div
                  v-else
                  class="menu-icon-list py-2"
                  @click="menu_selected = menu2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon right color="white">{{ menu2.icon }}</v-icon>
                </div>
              </template>
              <span>{{ menu2.text }}</span>
            </v-tooltip>
          </div>
        </div> -->
        <div class="ml-1">
          <v-menu offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-avatar v-bind="attrs" v-on="on">
                <v-img src="https://randomuser.me/api/portraits/women/75.jpg" />
              </v-list-item-avatar>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-img
                    :src="'https://randomuser.me/api/portraits/women/75.jpg'"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{
                  $store.state.profile.name
                }}</v-list-item-title>
              </v-list-item>
              <!--<v-list-item prepend-icon="mdi-account">
                <v-list-item-content>
                  :to="$router.push({name:'customer-customer-update', params:{id: $store.state.profile._id || ''}})" 
                  <v-list-item-title
                    ><v-icon left color="primary">mdi-account</v-icon>
                    แก้ไขข้อมูลส่วนตัว</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>-->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="cursor-logout" @click="logout"
                    ><v-icon left color="primary">mdi-logout</v-icon>
                    ออกจากระบบ</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>

    <v-navigation-drawer
      :width="drawer || !hasNavigation ? menu_icon_width : 250"
      color="grey lighten-5"
      app
    >
      <div
        v-if="menu_selected"
        class="text-h4 mt-5 ml-2 primary--text text-center"
      >
        {{ menu_selected.text }}
      </div>
      <div v-if="menu_selected" class="sub-menu pl-15">
        <div
          class="my-5 grey--text"
          v-for="item in menu_selected.sub_menu"
          :key="item.name"
          link
        >
          <router-link
            class="sub-menu-list"
            :to="item.url"
            v-on:click.native="(submenu_selected = item.name), (drawer = true)"
            v-if="item.show || item.show == 2"
          >
            {{ item.name }}
          </router-link>
          <!-- <div class="sub-menu-list" :class="{'active':item.name==submenu_selected}" @click="submenu_selected=item.name">{{item.name}}</div> -->
        </div>
      </div>

      <template v-slot:append>
        <div class="sub-menu pl-15 mb-2">
          <v-row align="center" dense>
            <!-- <v-col cols="3" class="pt-3">สาขา</v-col>
            <v-col cols="9" class="pr-3">
              <v-select
                :value="$store.state.selected_branch._id"
                :items="$store.state.list.branchs"
                item-value="_id"
                item-text="branch_name"
                hide-details
                dense
                @input="(_id) => $store.commit('setSelectedBranch', _id)"
              ></v-select>
            </v-col> -->
            <v-col cols="12" class="mt-6">มีปัญหาใช้งานโปรแกรม</v-col>
            <!-- <v-col cols="2">โทร:</v-col>
            <v-col cols="10">098-686-2277</v-col> -->
            <v-col cols="2">Line:</v-col>
            <v-col cols="10">@P9944 (มีตัว @ จ้า)</v-col>
          </v-row>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar v-if="hasNavigation" flat color="white" dense app>
      <v-btn color="grey" outlined x-small @click="drawer = !drawer">
        <div v-if="!drawer"><v-icon right>mdi-menu-left</v-icon> ซ่อน</div>
        <div v-else><v-icon right>mdi-menu-right</v-icon> แสดง</div>
      </v-btn>
      <!-- <v-row>
      <v-col cols="1" offset="9" class="text-right">สาขาปัจจุบัน</v-col>
      <v-col cols="2" class="pt-1">
              <v-select
                :value="$store.state.selected_branch._id"
                :items="$store.state.list.branchs"
                item-value="_id"
                item-text="branch_name"
                hide-details
                dense
                @input="(_id) => $store.commit('setSelectedBranch', _id)"
              ></v-select>
            </v-col>
      </v-row> -->
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view v-if="!$store.getters.isLoading" :key="componentKey"/>
      </v-container>
    </v-main>

    <LoadingPage />
  </v-app>
</template>


<script>
import Vue from "vue";
import LoadingPage from "@/components/LoadingPage.vue";
import { mapState } from 'vuex'
// const asyncLocalStorage = {
//     setItem: function (key, value) {
//         return Promise.resolve().then(function () {
//             localStorage.setItem(key, value);
//         });
//     },
//     getItem: function (key) {
//         return Promise.resolve().then(function () {
//             return localStorage.getItem(key);
//         });
//     }
// };
export default {
  components: {
    LoadingPage,
  },
  data: () => ({
    // selected_branch: {
    //   _id: 0,
    //   branch_name: "",
    //   phone_number: "",
    // },
    componentKey: 0,
    list: {
      branchs: [],
    },
    menu_icon_width: 50,
    name: "Dashboard",
    drawer: false,

    menu_selected: "",
    submenu_selected: "",


        // sub_menu: [
        //   { name: "ใบเสร็จรับเงิน", url: "/backoffice/receipt", show: true },
        //   { name: "ลูกค้าเพิ่มตัง", url: "/backoffice/customer", show: true },
        //   { name: "สินค้าเพิ่มตัง", url: "/backoffice/product", show: true },
        // ],
    menu_list: [
      {
        name: "receipt",
        icon: "mdi-currency-usd",
        text: "ใบเสร็จรับเงิน",
        url: "/backoffice/receipt", 
        show: true,
      },
      {
        name: "customer",
        icon: "mdi-account-box-multiple",
        text: "ลูกค้าเพิ่มตัง",
        url: "/backoffice/customer", 
        show: true,
      },
      {
        name: "product",
        icon: "mdi-cart",
        text: "สินค้าเพิ่มตัง",
        url: "/backoffice/product", 
        show: true,
      },
      {
        name: "coupon",
        icon: "mdi-ticket-percent",
        text: "คูปองส่วนลด",
        url: "/backoffice/coupon",
        show: true
      }
    ],

    // menu_list2: [
    //   {
    //     name: "backoffice",
    //     icon: " mdi-key-variant",
    //     text: "หลังบ้าน",
    //     show: true,
    //     sub_menu: [
    //       { name: "ใบเสร็จรับเงิน", url: "/backoffice/receipt" ,show: true},
    //       { name: "ลูกค้าเพิ่มตัง", url: "/backoffice/customer" ,show: true},
    //       { name: "สินค้าเพิ่มตัง", url: "/backoffice/product" ,show: true},
    //     ],
    //   },
    //   {
    //     name: "package",
    //     icon: " mdi-package-up",
    //     text: "แพ็คเกจ",
    //     show: true,
    //     sub_menu: [
    //       { name: "แพ็คเกจของคุณ", url: "bo/backoffice/package/your" ,show: true,},
    //       // // { name: 'ใบแจ้งหนี้', url: '/pages/package/invoice' },
    //       { name: "ประวัติการชำระเงิน", url: "bo/backoffice/package/payment" ,show: true,},
    //       // { name: 'บัตรเครดิต', url: '/pages/package/credit ' },
    //     ],
    //   },
    // ],

    links: ["Home", "Contacts", "Settings", "Menu 1", "Menu 2", "Menu 3"],
    mini: true,
    permission: {},
  }),

  mounted() {
    this.menu_selected = this.menu_list[0];
  },

  async created() {
    // this.$store.dispatch("getBranchs");
    this.$store.dispatch("loadingComplete");
    console.log('im here!');
  },

  methods: {
    goUrl(url) {
      this.$router.push(url).catch(() => {});
    },
    logout: async function () {
      this.$router.push({ name: "loginbackoffice" });
    },
   
    forceRerender() {
      this.componentKey += 1;
    }
  },

    computed: {
      hasNavigation() {
        return this.menu_selected && this.menu_selected.sub_menu
      },
      //  ...mapState({
      //   selected_branch: (state) => {
      //     return {_id: state.selected_branch._id,
      //     branch_name: state.selected_branch.branch_name,
      //     phone_number: state.selected_branch.phone_number}
      //   },
      // })
    },
  }
</script>

<style lang="scss" scoped>
$primary: #2ba9fc;
.menu-icon {
  position: fixed;
  height: 100%;
  background-color: $primary;
  z-index: 7;

  .menu-icon-list {
    cursor: pointer;
    padding-left: 2px;
    &:hover {
      background-color: #66a9e7;
    }
    &.active {
      border-left: white solid 2px;
      padding-left: 0px;
    }
  }
}

.sub-menu {
  .sub-menu-list {
    cursor: pointer;
    padding-left: 5px;
    text-decoration: none;
    color: #494949;

    &:hover {
      color: $primary;
    }
    &.router-link-exact-active {
      border-left: $primary solid 2px;
      padding-left: 3px;
    }
  }
}
.cursor-logout {
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}
.v-tooltip__content {
    background: #2196f3;
    color: #FFFFFF;
}
</style>
